<script lang="ts">
  import { Tooltip } from 'carbon-components-svelte'
  import Label from '../../../../../style/label.svelte'

  export let title = ''
  export let analysisData: any

  let tooltipOpen: boolean[] = []

  $: redLight = analysisData.filter((data: any) => !data.isValid && !data.isWarning)
  $: orangeLight = analysisData.filter((data: any) => data.isWarning)
  $: greenLight = analysisData.filter((data: any) => data.isValid)

  $: sortedAnalysisData = [...redLight, ...orangeLight, ...greenLight]
</script>

<div class="seo-section flex flex-col mt-10">
  <div class="flex text-lg font-bold">
    <span>{title}</span>
  </div>
  {#each sortedAnalysisData as data, index}
    {#if data.enabled}
      <div class="seo-analysis flex gap-3 mt-6 px-2">
        <div class="flex">
          <span
            class="h-6 w-6 rounded-full"
            class:green={data.isValid}
            class:orange={data.isWarning}
            class:red={!data.isValid && !data.isWarning}
          ></span>
        </div>
        <p class="text-sm">{data?.label}</p>
        <div
          class="score flex ml-auto"
          role="alert"
          on:mouseenter={() => (tooltipOpen[index] = true)}
          on:mouseleave={() => (tooltipOpen[index] = false)}
        >
          {#if data?.defaultScore !== 1}
            <Label>
              {data?.defaultScore}
              <Tooltip bind:open={tooltipOpen[index]} hideIcon direction="bottom" align="end">
                This rule has a different value than 1
              </Tooltip>
            </Label>
          {/if}
        </div>
      </div>
    {/if}
  {/each}
</div>

<style lang="postcss">
  .score :global(.my-label .bx--label) {
    margin-bottom: 0rem;
    align-content: center;
    color: var(--cds-text-02);
  }
  .score :global(.my-label .bx--tooltip__label) {
    display: none;
  }
  .score :global(.bx--tooltip) {
    padding: 0.188rem 1rem;
    margin-left: 10px;
  }
  .score :global(.bx--tooltip__content) {
    font-size: 0.9rem;
  }
  .green {
    @apply bg-[var(--green)];
  }
  .orange {
    @apply bg-[var(--orange)];
  }
  .red {
    @apply bg-[rgb(var(--red))];
  }
</style>
