<script lang="ts">
  import { getContext } from 'svelte'
  import Dropdown from '../../../../../atoms/filterable/carbon-dropdown.svelte'

  import FilterStyle from '../../../../../../style/style-filter.svelte'
  import { SEARCH_PANE_CONTEXT } from '../../search-pane'

  const { activeFilterManager } = getContext(SEARCH_PANE_CONTEXT) as any

  $: filterManager = $activeFilterManager
  $: date = filterManager.date
  $: selected = date.selected
  $: items = date.items
</script>

<FilterStyle>
  <div class="filter single-list rounded-[var(--round)]">
    <Dropdown bind:selectedId={$selected} bind:items={$items} filterable={false} floatingTitleText="Date" />
  </div>
</FilterStyle>
