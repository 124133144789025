<script lang="ts">
  import { onMount, setContext } from 'svelte'
  import { get } from 'svelte/store'
  import { tweened } from 'svelte/motion'
  import { cubicInOut } from 'svelte/easing'

  import { type DeskContent } from 'common/src/types'
  import { nanoid } from 'nanoid'
  import { atom } from 'nanostores'

  import CloseLarge from 'carbon-icons-svelte/lib/CloseLarge.svelte'

  import { state } from '../../../store/state'

  import MyIcon from '../../atoms/my-icon.svelte'

  import AIIcon from '../../../assets/icons/ai-logo-svg.svelte'

  import { COLUMNS_UPDATE_DELAY, RIGHT_PANEL_CONTEXT, isActiveOnContent } from './right-panel'

  import { type PaneElement, paneList } from './pane-list'
  import RightPanelButton from './right-panel-button.svelte'

  export let content: DeskContent

  export let width = 0

  const OPEN_DELAY = 600
  const { settings } = state
  const { open, pinned, current, searchKind, inTransition, handleOpen, handleClose, select } =
    state.rightPanel

  let mainElement: HTMLDivElement
  let focused = false
  let openRequest = ''

  const handleClickOutside = (event: any) => {
    if (mainElement && !mainElement.contains(event.target) && !$pinned) {
      $open = false
    }
  }

  const animationOpts = {
    duration: 700,
    easing: cubicInOut,
  }
  const panelWidth = tweened(0, animationOpts)
  const panelWidthMultiplier = 19

  const animate = async (rem: number) => {
    inTransition.set(true)
    await panelWidth.set(rem)
    inTransition.set(false)

    if ($pinned) {
      width = get(panelWidth) * panelWidthMultiplier
    }
  }

  onMount(() => {
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  })

  $: data = settings.data
  $: multiplier = $data.columnMultiplier || 1

  $: $open ? animate(25.0 * multiplier) : animate(0.0)
  $: width = $pinned ? (width = $panelWidth * panelWidthMultiplier) : (width = 0.0)

  const columns = atom(multiplier)

  setContext(RIGHT_PANEL_CONTEXT, {
    open,
    pinned,
    searchKind,
    aiRouter: state.aiRouter,
    columns,
  })

  const updateColumns = (multiplier: number) => {
    setTimeout(() => ($columns = multiplier), COLUMNS_UPDATE_DELAY)
  }

  $: {
    updateColumns($data.columnMultiplier)
  }

  const coreElements = paneList.filter((item) => item.activeOn === '*')
  const kindElements = paneList.filter((item) => item.activeOn !== '*')

  const handleMouseEnter = (element: PaneElement) => {
    focused = true

    if (!$pinned) {
      select(element)
    }

    const myRequest = nanoid()
    openRequest = myRequest

    setTimeout(() => {
      if (!focused || openRequest !== myRequest) {
        return
      }

      handleOpen(element)
    }, OPEN_DELAY)
  }
</script>

<div
  bind:this={mainElement}
  class="right-panel fixed h-fit right-0 flex flex-row-reverse bg-transparent border-t border-solid border-[var(--selected)]"
  on:mouseleave={() => {
    focused = false
    handleClose()
  }}
  role="dialog"
>
  <div class="h-full flex flex-col z-50 bg-transparent gap-10">
    <div class="flex flex-col" role="dialog">
      {#each coreElements as element}
        <RightPanelButton {element} onMouseEnter={handleMouseEnter} {content} />
      {/each}
    </div>
    <div class="flex flex-col" role="dialog">
      {#each kindElements as element}
        <RightPanelButton
          {element}
          onMouseEnter={handleMouseEnter}
          active={isActiveOnContent(element, content)}
          {content}
        />
      {/each}
    </div>
  </div>

  <div
    style="width: calc({$panelWidth}rem)"
    class="right-panel-content flex flex-col relative overflow-hidden bg-[var(--background)]"
  >
    <div
      class="content flex flex-col flex-grow border-x border-b border-solid border-[var(--selected)] min-w-full max-w-full z-40"
    >
      <div class="flex flex-row justify-between px-4 pt-4 pb-4">
        {#if paneList[$current]?.id == 0}
          <div class="icon flex flex-row gap-2">
            <MyIcon icon={AIIcon} />
            <p class="title-internal">{paneList[$current]?.name}</p>
          </div>
        {:else}
          <p class="self-end">{paneList[$current]?.name}</p>
        {/if}

        {#if $pinned}
          <button
            class="bg-transparent"
            on:click={() => {
              $open = false
              $pinned = false
            }}
          >
            <MyIcon icon={CloseLarge} type="danger" dimension="xl" />
          </button>
        {/if}
      </div>
      {#each paneList as element}
        <div class="flex w-full h-full max-h-full flex-grow-0" class:hidden={$current !== element.id}>
          <svelte:component this={element.component} {content} />
        </div>
      {/each}
    </div>
  </div>
</div>

<style lang="postcss">
  .right-panel {
    --content-height: calc(
      100vh - (var(--inner-content-padding) * 4 + var(--bars-size) + var(--toolbar-button-height))
    );
    margin-top: var(--inner-content-padding);
    margin-right: 0.4rem;
  }

  .right-panel-content,
  .right-panel-content .content {
    height: var(--content-height);
    max-height: var(--content-height);
  }

  .title-internal {
    margin-top: 0.3rem;
  }

  .icon :global(.my-icon) {
    height: 2rem;
    width: 2rem;
    display: flex;
    align-items: center;
  }
  .icon :global(.my-icon > svg) {
    height: auto;
    width: 100%;
  }
</style>
