<script lang="ts">
  import { getContext } from 'svelte'

  import MultiSelect from '../../../../../atoms/filterable/carbon-multi-select.svelte'

  import { state } from '../../../../../../store/state'

  import FilterStyle from '../../../../../../style/style-filter.svelte'
  import { SEARCH_PANE_CONTEXT } from '../../search-pane'

  const { activeFilterManager } = getContext(SEARCH_PANE_CONTEXT) as any

  const DEFAULT_LABEL = `-`
  export let labelMessage = DEFAULT_LABEL
  export let floatingTitleText: string
  export let type: 'partitions' | 'sources'
  export let filterable = false

  $: filterManager = $activeFilterManager
  $: filter = filterManager[type.slice(0, type.length - 1)]
  $: selected = filter.selected
  $: items = filter.items
  $: lookups = state.lookups
  $: {
    if (lookups && filter) {
      const data = lookups.get()[type]
      filter.setOptions(data)

      selected = filter.selected
    }
  }
  $: {
    const sel = $selected || []
    if (sel.length === 0) {
      labelMessage = DEFAULT_LABEL
    } else if (sel.length > 2) {
      labelMessage = `${$selected.length} ${filter.name} selected`
    } else {
      let first = $items.find((element: any) => element.id === $selected[0])
      let second = $items.find((element: any) => element.id === $selected[1])

      labelMessage = `${first?.text}${second ? ', ' + second.text : ''}`
    }
  }
</script>

<FilterStyle>
  <div class="filter filter-control single-list rounded-[var(--round)]">
    <MultiSelect
      bind:selectedIds={$selected}
      bind:items={$items}
      label={labelMessage}
      {filterable}
      type="default"
      {floatingTitleText}
    />
  </div>
</FilterStyle>

<style lang="postcss">
  .filter-control :global(.bx--multi-select__wrapper) {
    position: relative;
  }

  .filter-control :global(.bx--multi-select .bx--tag) {
    display: none;
  }
</style>
