<script lang="ts">
  import { type DeskContent } from 'common/src/types'
  import { state } from '../../../store/state'
  import MyButton from '../../atoms/my-button.svelte'
  import { ASK_AI_TAB_NAME, SEO_TAB_NAME, type PaneElement } from './pane-list'
  import AiButton from './custom-buttons/ai-button.svelte'
  import SeoButton from './custom-buttons/seo-button.svelte'

  export let element: PaneElement
  export let onMouseEnter: (element: PaneElement) => void
  export let active: boolean = true
  export let content: DeskContent

  let customClasses: string = ''

  const { open, current, setPinned } = state.rightPanel

  const wrapMouseEnter = () => {
    if (!active) {
      return
    }

    onMouseEnter(element)
  }

  $: isActiveOnContent = content && (element.activeOn.includes(content.type) || element.activeOn === '*')
  $: selected = element.id === $current
</script>

<div
  class="right-button w-full"
  class:active={$current === element.id && $open}
  class:inactive={$current !== element.id || !$open}
  class:selected
  class:deselected={!selected}
  class:disabled={!isActiveOnContent}
  class:enabled={isActiveOnContent}
>
  <MyButton kind="ghost" onClick={() => setPinned(element)} disabled={!active}>
    <!-- svelte-ignore a11y-no-static-element-interactions -->
    <div
      class={`button-content flex w-full gap-2 p-4 text-left whitespace-nowrap ${customClasses}`}
      on:mouseenter={wrapMouseEnter}
    >
      {#if element.name === ASK_AI_TAB_NAME}
        <div class="icon flex flex-row">
          <AiButton name={element.name} />
        </div>
      {:else if element.name === SEO_TAB_NAME}
        <div class="icon flex flex-row">
          <SeoButton name={element.name} {selected} enabled={isActiveOnContent} bind:customClasses />
        </div>
      {:else}
        {element.name}
      {/if}
    </div>
  </MyButton>
</div>

<style lang="postcss">
  .green {
    @apply bg-[var(--green)];
  }
  .orange {
    @apply bg-[var(--orange)];
  }
  .red {
    @apply bg-[rgb(var(--red))];
  }

  .right-button {
    background-color: var(--background);
  }

  .right-button.disabled {
    background-color: var(--cds-disabled-02);
  }

  .right-button.disabled:hover {
    color: var(--cds-disabled-03);
  }

  .right-button :global(.my-button) {
    padding: unset;
    width: 100%;
    box-shadow: unset;
    border: unset;
  }

  .right-button.enabled.deselected :global(.my-button:hover) {
    color: unset;
    background-color: var(--hover-color);
  }

  .right-button.enabled.selected :global(.my-button:hover) {
    color: unset;
    background-color: rgba(var(--accent-rgb), 0);
  }

  .right-button div {
    transition: border-color 0.3s ease;
  }
  .selected {
    --background: rgba(var(--accent-rgb), 0.5);
  }
  .selected:hover {
    --background: rgba(var(--accent-rgb), 0.35);
  }

  .active,
  .inactive {
    border-bottom: 1px solid var(--selected);
  }

  .active {
    border-left: 1px solid transparent;
  }

  .inactive {
    border-left: 1px solid var(--selected);
  }

  .right-button :global(.my-button.bx--btn--ghost.bx--btn--disabled) {
    background-color: var(--cds-disabled-02, #f4f4f4);
  }

  .icon {
    @apply gap-2;
    transition: gap 0.5s;
  }
  .active .icon {
    @apply gap-0;
  }
  .icon :global(.my-icon) {
    height: 2rem;
    width: 2rem;
    display: flex;
    align-items: center;
    transition: width 0.5s;
  }
  .active .icon :global(.my-icon) {
    width: 0;
  }
  .icon :global(.my-icon > svg) {
    height: auto;
    width: 100%;
  }
  .button-content :global(.title) {
    font-size: 14px;
    margin-top: 0.3rem;
  }
</style>
