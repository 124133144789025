<script lang="ts">
  import { type DeskContent } from 'common/src/types'

  import DropHint from '../atoms/drop-hint.svelte'

  import { state } from '../../store/state'
  import { type DnDHint } from '../../store/dnd-hints'
  import { buildAction } from './out-of-bound-hints'
  import { updateContent } from './editor-shell'

  export let content: DeskContent
  export let isInEdit = false
  export let position: 'above' | 'below' = 'above'

  const build = (prop: string) => buildAction(content, prop, updateContent, state)

  $: hints = state.dragAndDropHints
  $: list = ($hints.hints as (DnDHint & { position: 'above' | 'below' })[]).filter(
    (hint) => hint.position === position
  )
</script>

<div class={`${position === 'below' ? 'bottom-4' : 'mt-4'} left-4 absolute flex flex-row gap-8 z-40`}>
  {#if isInEdit}
    {#each list as hint}
      <DropHint
        dropAction={build(hint.name)}
        hidden={hint.hidden}
        selected={hint.selected}
        text={hint.text}
      />
    {/each}
  {/if}
</div>
