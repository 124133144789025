import { Writable, get } from 'svelte/store'
import { State } from '../../../../store/state'
import { ContentKind } from '../../../../store/content-kind'
import { FilterManager } from '../../../content/filter/filter-manager'
import { DEFAULT_SELECTED_KIND } from '../right-panel'

export const SEARCH_PANE_CONTEXT = 'SearchPane'

export const filterManagers = (state: State) => {
  const fm: Record<string, FilterManager> = {}
  let defaultNameOnState = 'images'

  for (const kind of state.searchContents) {
    fm[kind.kind.nameOnState] = new FilterManager(kind, state)
    if (kind.kind.nameOnState.toLocaleLowerCase() === `search${DEFAULT_SELECTED_KIND}`) {
      defaultNameOnState = kind.kind.nameOnState
    }
  }

  return { fm, defaultNameOnState }
}

export const adjustPageSize = (pageSize: number, contentKind: ContentKind<any>) => {
  const cps = contentKind.pageSize.get()
  if (cps !== pageSize) {
    contentKind.pageSize.set(pageSize)
  }
}

export const setKind = async (
  kind: string,
  filterManagers: Record<string, FilterManager>,
  activeFilterManager: Writable<any>,
  activeContentKind: Writable<any>,
  pageSize = 48
) => {
  const currentContentKind = get(activeContentKind)
  if (kind === currentContentKind?.kind.nameOnState) {
    const fm = get(activeFilterManager) as FilterManager
    if (fm) {
      adjustPageSize(pageSize, currentContentKind)
      fm.doFilter(undefined, true)
    }

    return
  }

  const currentFilter = currentContentKind?.filter.get()
  const newContentKind = filterManagers[kind].contentKind
  const fm = filterManagers[kind]
  adjustPageSize(pageSize, newContentKind)
  if (currentFilter) {
    newContentKind.filter.set(currentFilter)
  }
  fm.doFilter(undefined, true)

  activeFilterManager.set(fm)
  activeContentKind.set(newContentKind)
}
