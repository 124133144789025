<script lang="ts">
  import { onMount } from 'svelte'
  import { writable } from 'svelte/store'
  import { Button, ProgressBar, TooltipIcon } from 'carbon-components-svelte'
  import Locked from 'carbon-icons-svelte/lib/Locked.svelte'
  import Information from 'carbon-icons-svelte/lib/Information.svelte'
  import InformationFilled from 'carbon-icons-svelte/lib/InformationFilled.svelte'
  import type { DeskContent } from 'common/src/types'
  import { isDraft, isValidId } from 'common/src/content'
  import MyClipboardButton from '../../atoms/my-clipboard-button.svelte'
  import FakeLink from '../../../style/fake-link.svelte'
  import { State } from '../../../store/state'
  import { NullUser } from '../../../store/user'
  import {
    toggleLock,
    getLock,
    canLand as buildCanLand,
    setQueryString,
    cancelDraft,
    lockInfo,
    toolbarBusy,
    contentCreation,
    toolbarUploadProgress,
    checkUpdates,
    onSaveButton
  } from './toolbar'
  import { router } from '../../../routing/router'
  import { type ValidationStatus } from '../../editor/validation-handler'
  import { filteredStatus, type FilteredStatus } from '../../../store/statuses'
  import { reportInfo, reportWarning } from '../../../store/notifications'

  export let content: DeskContent
  export let state: State
  export let isInEdit = false
  export let lockedBy = ''
  export let validationStatus: ValidationStatus
  export let hasErrors = false

  export let updateLock = () => {
    if (isDraft(content?.id)) {
      isInEdit = true
      lockedBy = ''
      lockedBySomeOneElse = false
    } else {
      const info = lockInfo(content, state)
      isInEdit = info.isInEdit
      lockedBy = info.lockedBy
      lockedBySomeOneElse = info.lockedBySomeOneElse
    }

    setQueryString(isInEdit, state)
  }

  let isDisabled = true
  let showVersion = false
  let forceCheckInProgress = false
  let id: string | undefined
  let lockedBySomeOneElse = false
  let canLand: (current: string | undefined, target: string) => boolean

  const subscriptions: (() => void)[] = []

  const statuses = writable<FilteredStatus[]>([])

  const doTheMath = () => {
    if (!isValidId(content?.id)) {
      return
    }

    updateLock()
    getLock(content, state)
  }

  const toggle = async () => {
    if (!isValidId(content?.id)) {
      return
    }

    await toggleLock(content, state, (newContent) => {
      content = newContent
    })

    // TODO: implement optimistic update.
  }

  const statusToKind = (status: string) => {
    return isInEdit ? 'primary' : status === content?.status ? 'primary' : 'ghost'
  }

  const save = (status: string, lastButton: boolean) => {
    onSaveButton(content, status, lastButton, state, doTheMath)
  }

  const forceCheck = async () => {
    forceCheckInProgress = true
    const res = await checkUpdates(content)
    if (res?.isOutdated) {
      reportWarning(`content was out of date, now is updated`, state)
      content = res.remoteContent
    } else {
      reportInfo(`content is already up to date`, state)
    }
    forceCheckInProgress = false
  }

  subscriptions.push(
    state.loggedUser.subscribe((user) => {
      if (!(user instanceof NullUser) && content) {
        doTheMath()
      }
    })
  )
  subscriptions.push(
    state.lookups.subscribe(async (lookups) => {
      if (!isValidId(content?.id)) {
        statuses.set([])
      }

      const remote = filteredStatus(content?.type, state)
      canLand = buildCanLand(lookups.remoteData.statuses, content?.type)
      statuses.set(remote)
    })
  )
  subscriptions.push(
    state.tabs.isLoading.listen((loadingArray) => {
      if (content) {
        toolbarBusy.set(loadingArray.includes(content.id))
      }
    })
  )

  subscriptions.push(
    state.locks.subscribe(() => {
      if (isValidId(content?.id)) {
        updateLock()
      }
    })
  )

  onMount(() => {
    return () => subscriptions.forEach((sub) => sub())
  })

  const { isLoading } = state.tabs

  const { uploadProgress } = toolbarUploadProgress

  $: {
    if (content && !lockedBySomeOneElse && !isInEdit) {
      if (router(state).route().params.edit) {
        toggle()
      }
    }
  }

  $: {
    if (content) {
      statuses.set(filteredStatus(content?.type, state)) // force update
      canLand = buildCanLand(state.lookups.get().remoteData.statuses, content?.type)
    }

    if (content?.id !== id && !(state.loggedUser.get() instanceof NullUser)) {
      doTheMath()
    }

    id = content?.id || ''

    // eslint-disable-next-line no-self-assign
    isInEdit = isInEdit
    // eslint-disable-next-line no-self-assign
    lockedBy = lockedBy
    isDisabled = !isInEdit || lockedBySomeOneElse
  }

  $: {
    if (validationStatus) {
      isDisabled = !isInEdit || lockedBySomeOneElse || validationStatus.counter > 0
    }
  }

  //TODO: remove this junk
  $: demoUser = state.loggedUser.get().username === 'demo'

  $: toolbarAvailable =
    $toolbarBusy && ($isLoading.includes(content.id) || (isDraft(content?.id || '') && $contentCreation))
  $: progress = $uploadProgress.find((p) => p.id === content.id)?.progress || 0
</script>

<div class="content-toolbar flex flex-row justify-between" class:hasErrors>
  <div class="status-jumper">
    {#each $statuses as status}
      <span
        class={`btn btn-${status.label} pr-2 min-w-[134rem]`}
        class:isInEdit
        class:not-in-edit={!isInEdit}
        class:current={status.label === content?.status && !content.id.startsWith('draft')}
      >
        <Button
          kind={statusToKind(status.label)}
          disabled={isDisabled ||
            !canLand(content?.status, status.label) ||
            demoUser ||
            toolbarAvailable ||
            status.disabled}
          on:click={() => save(status.label, false)}>{isInEdit ? status.action : status.label}</Button
        >
      </span>
    {/each}
    {#if isInEdit && demoUser}
      <span>DISABLED FOR DEMO USER</span>
    {/if}
    <div class="version inline-block mt-4 ml-2" class:mt-[0.3rem]={showVersion}>
      <div class="flex flex-row">
        <TooltipIcon
          tooltipText={`Document version: ${content?.version}`}
          icon={showVersion ? InformationFilled : Information}
          direction={'right'}
          on:click={() => (showVersion = !showVersion)}
        />
        <div class:hidden={!showVersion}>
          <div class="version-drawer flex flex-row gap-2 p-2 ml-2">
            <p class="text-sm">
              Document version: {content?.version}
            </p>

            <FakeLink disabled={forceCheckInProgress}>
              <button on:click={forceCheck}>
                {forceCheckInProgress ? 'checking...' : 'check for updates'}
              </button>
            </FakeLink>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="copy-paste self-start h-full items-center mt-2 ml-2">
    <MyClipboardButton padding="0.25rem" {state} bind:relatedContent={content} />
  </div>
  <div class="lock flex flex-row grow justify-end">
    <div class={`flex self-end flex-row gap-1 ${lockedBySomeOneElse ? 'other-lock' : 'my-lock'}`}>
      {#if lockedBy}
        <div class="lock-icon">
          <TooltipIcon tooltipText={lockedBy} icon={Locked} direction="left" />
        </div>
      {/if}
      <Button
        id={`qa-test-toolbar-${isInEdit ? 'cancel' : 'edit'}`}
        kind={isInEdit ? 'danger' : 'primary'}
        disabled={lockedBySomeOneElse || (isInEdit && toolbarAvailable)}
        on:click={() => {
          if (isDraft(content)) {
            return cancelDraft(state, content)
          }
          toggle()
        }}>{isInEdit ? 'Cancel' : 'Edit'}</Button
      >
      <Button
        id="qa-test-toolbar-save"
        disabled={!isInEdit || hasErrors || toolbarAvailable || demoUser}
        on:click={() => {
          if (content) {
            save(content.status, true)
          }
        }}>Save & Unlock</Button
      >
    </div>
  </div>
</div>
<!-- upload progress -->
<div class:hidden={progress <= 0} class="flex flex-row items-center gap-4 mt-3 w-full">
  <Button
    disabled={!isInEdit || progress === 100}
    kind="danger"
    on:click={() => toolbarUploadProgress.stopUpload(content.id)}>Cancel {Math.round(progress)}%</Button
  >
  <div class="w-full"><ProgressBar size="sm" value={progress} /></div>
</div>
{#if hasErrors && isInEdit}
  <div class="flex flex-row gap-1 pt-3 pb-1">
    <p class="text-error text-sm">
      <b>{validationStatus.counter}</b> fields are incomplete or contain invalid data. It is not possible to
      save the {router(state).firstSegment()}
      until all errors are resolved.
    </p>
    <TooltipIcon tooltipText={validationStatus.detail} icon={Information} direction="right" />
  </div>
{/if}

<style lang="postcss">
  .status-jumper .btn :global(.bx--btn),
  .lock :global(.bx--btn) {
    height: var(--toolbar-button-height);
    min-height: var(--toolbar-button-height);
    padding-block: 0;
  }
  .status-jumper .btn.btn.isInEdit :global(.bx--btn),
  .status-jumper .btn.btn.not-in-edit :global(.bx--btn) {
    @apply min-w-[10rem];
  }

  .status-jumper .btn.btn.not-in-edit :global(.bx--btn) {
    @apply text-[var(--status-button-text)];
  }

  .status-jumper .btn:not(.current) :global(:not(.bx--btn--disabled)) {
    @apply bg-[rgb(var(--accent-rgb))] hover:bg-[rgba(var(--accent-rgb),0.6)] active:bg-[rgba(var(--accent-rgb),0.7)];
  }

  .status-jumper .btn.current.not-in-edit :global(.bx--btn) {
    background-color: var(--accent);
  }

  .status-jumper .btn.isInEdit :global(.bx--btn--disabled) {
    @apply bg-transparent text-[var(--status-button-text)];
  }

  .status-jumper .btn.isInEdit :global(:not.bx--btn--disabled) {
    @apply bg-error;
  }

  .status-jumper .btn.isInEdit :global(.bx--btn--disabled),
  .status-jumper .btn.not-in-edit :global(.bx--btn) {
    @apply border-[1px];
    border-color: var(--accent);
    border-radius: var(--round);
  }

  .lock :global(svg) {
    @apply w-8 h-8;
  }

  .lock .other-lock :global(svg path) {
    @apply fill-error;
  }

  .lock-icon :global(button) {
    @apply mt-[0.5rem] mr-[0.5rem];
  }

  .version :global(span) {
    user-select: text;
  }

  .version-drawer {
    border: 1px solid var(--selected);
  }
</style>
